@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,300;1,400;1,600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn {
  white-space: break-spaces;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  scroll-behavior: smooth;
  scrollbar-arrow-color: #00adff;
  font-family: "Lato", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
li,
label,
input,
select {
  font-weight: 300 !important;
}

a:hover {
  text-decoration: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

body {
  background-color: #000;
  color: #fff;
}

.full-page {
  max-height: 100vh;
  max-width: 100%;
  min-height: 100vh;
  min-width: 100%;
  overflow: hidden;
}

#background-video {
  width: 100%;
}

.bg-fixed {
  background-attachment: fixed;
  background-size: cover;
  background-position-x: center;
  background-repeat: no-repeat;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  /* -webkit-transform: translateZ(0); */
  -o-background-attachment: fixed;
  -moz-background-attachment: fixed;
  -webkit-background-attachment: fixed;
  -o-background-position-: no-repeat;
  -moz-background-position-: no-repeat;
  -webkit-background-position-: no-repeat;
}

.diamonds {
  background-image: url("");
}

.jwellery {
  background-image: url("https://web-images-divina.s3.ap-south-1.amazonaws.com/website-images-content/jewelery-landing-page.jpg");
}

.estate-jewelry {
  background-image: url("https://web-images-divina.s3.ap-south-1.amazonaws.com/website-images-content/estate-page.jpg");
}

.custom-design {
  background-image: url("https://web-images-divina.s3.ap-south-1.amazonaws.com/website-images-content/custom.jpg");
}

.we-buy {
  background-image: url("https://web-images-divina.s3.ap-south-1.amazonaws.com/website-images-content/we-buy.jpg");
}

.contact-us {
  background-image: url("https://web-images-divina.s3.ap-south-1.amazonaws.com/website-images-content/contact-us.jpg");
}

.category-heading-name {
  font-family: "Work Sans", sans-serif;
  color: #fff;
  font-size: 90px;
  text-transform: uppercase;
  letter-spacing: 10px;
  word-spacing: 30px;
}

.home-page-blur-bg {
  background-color: rgb(0 0 0 / 68%);
  width: 100%;
  height: auto;
}

.blur-bg {
  /* background-color: rgb(0 0 0 / 68%); */
  background: linear-gradient(
    0deg,
    rgb(0 0 0 / 97%) 0%,
    rgb(68 68 68 / 49%) 100%
  );
  width: 100%;
  height: auto;
}

nav {
  background: #000;
}

.nav-item {
  color: #fff;
}

.nav-item p {
  /* font-size: 25px; */
  font-family: "Lato", sans-serif;
  font-weight: 100;
}

.logo {
  width: 100px;
  height: auto;
}

.cat-jumbo {
  width: 100%;
  height: 400px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* background-position-x: -50px; */
}

.rings {
  /* background-image: url(/static/media/bg-ring.c4329cef.png); */
}

.jumbo-cat-name {
  font-size: 4rem;
  font-family: "Cormorant Garamond", serif !important;
}

.desc-text {
  font-size: 20px;
  font-weight: 300;
  /* text-transform: capitalize; */
}

.footer-links li {
  margin: 6px 0px;
  font-family: "Lato", sans-serif;
  font-size: 12px;
}

.footer {
  border-top: 1px solid white;
}

.jwelery-cats li {
  margin: 2rem 0rem;
  font-size: 25px;
}

.jwelery-cats-first li {
  margin: 5rem 0rem;
  font-size: 25px;
}

.jwellery-cat-banner {
  background-repeat: no-repeat;
  background-size: contain;
  align-self: stretch;
}

.contact-banner {
  background-repeat: no-repeat;
  /* background-position: -185px 0; */
  background-size: cover;
  align-self: stretch;
}

.address-line {
  font-size: 20px;
  font-family: "Lato", sans-serif;
  letter-spacing: 4px;
  font-weight: 100;
}

.contact-heading {
  font-family: "Cormorant Garamond", serif;
}

.contact-icon {
  font-size: 50px;
  margin: 0 2rem;
}

.text-big-35 {
  font-size: 35px;
}

.text-big-50 {
  font-size: 50px;
}

.wc-divina-text {
  font-family: "Lato", sans-serif;
  font-weight: 100;
}

.ft-lato {
  font-family: "Lato", sans-serif;
  font-weight: 100;
}

.ft-lato-bold {
  font-family: "Lato", sans-serif;
  font-weight: bold;
  letter-spacing: 3px;
}

.bg-grey {
  background: #1b1b1b;
}

.page-config select {
  background: #1b1b1b;
  color: #fff;
  font-size: larger;
}

.estate-item-box {
  border-radius: 5px;
}

.estate-name {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.we-buy-text-desc {
  font-size: 20px;
}

.form-bottom-border-parent {
  position: relative;
}

.form-bottom-border-parent label {
  position: absolute;
  top: 16px;
  left: 10px;
  transition: 0.2s all ease;
  font-size: 13px;
}

.form-bottom-border-parent {
  margin-bottom: 30px;
}

.form-bottom-border-parent input {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #dde;
  min-height: 48px;
  background: transparent;
}

.form-bottom-border-parent textarea {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #dde;
  min-height: 48px;
  background: transparent;
}

.form-bottom-border-parent input:focus {
  outline: none;
  box-shadow: none;
  border-color: #fff;
  border-width: 2px;
  background: transparent;
}

.form-bottom-border-parent textarea:focus {
  outline: none;
  box-shadow: none;
  border-color: #fff;
  border-width: 2px;
  background: transparent;
}

label.active {
  top: -14px;
  transition: 0.2s all ease;
}

.upload-inventory {
  position: relative;
  background: #1b1b1b;
  padding: 20px 0px;
  border-radius: 20px;
}

.upload-inventory-fld {
  position: absolute;
  opacity: 0;
  background: yellow;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.contact-col {
  background: #1b1b1b;
}

.fs-2r {
  font-size: 2rem;
}

.fs-28 {
  font-size: 28px;
}

.fs-10 {
  font-size: 10px;
}

.fs-12 {
  font-size: 12px;
}

.fs-20 {
  font-size: 20px;
}

.fs-18 {
  font-size: 18px;
}

.fs-25 {
  font-size: 25px;
}

.fs-30 {
  font-size: 30px;
}

.fs-35 {
  font-size: 35px;
}

.fs-40 {
  font-size: 40px;
}

.fw-200 {
  font-weight: 200;
}

.fw-400 {
  font-weight: 400;
}

.fw-600 {
  font-weight: 600;
}

.fw-800 {
  font-weight: 800;
}

.td-none:hover {
  text-decoration: none;
  box-shadow: none;
  color: inherit;
}

.td-none {
  color: inherit;
}

.ft-cormo {
  font-family: "Cormorant Garamond", serif;
}

.shape-height {
  height: 5.5rem;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
}

.not-show-more {
  overflow: hidden;
  height: 100px;
}

.hover-pointer:hover {
  cursor: pointer;
}

.radius-left {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  outline: none;
}

.radius-right {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  outline: none;
}

.radius-none {
  border-radius: 0px;
}

.numeric-inp input {
  max-width: 150px;
}

.numeric-inp .btn {
  padding: 0px !important;
}

/* Common styles and resets */

.dotstyle ul {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style: none;
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dotstyle li {
  position: relative;
  display: block;
  float: left;
  margin: 0 16px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.dotstyle li a {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: none;
  border-radius: 50%;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.3);
  text-indent: -999em;
  /* make the text accessible to screen readers */
  cursor: pointer;
  position: absolute;
}

.dotstyle li a:focus {
  outline: none;
}

/* Individual styles and effects */

/* Fill up */

.dotstyle-fillup li a {
  overflow: hidden;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px white;
  transition: background 0.3s ease;
}

.dotstyle-fillup li a::after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0 1px #fff;
  transition: height 0.3s ease;
}

.dotstyle-fillup li a:hover,
.dotstyle-fillup li a:focus {
  background-color: rgba(0, 0, 0, 0.2);
}

.dotstyle-fillup li.current a::after {
  height: 100%;
}

/* Scale up */

.dotstyle-scaleup li a {
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.dotstyle-scaleup li a:hover,
.dotstyle-scaleup li a:focus {
  background-color: white;
}

.dotstyle-scaleup li.current a {
  background-color: white;
  transform: scale(1.5);
}

/* Stroke */

.dotstyle-stroke li a {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0);
  transition: box-shadow 0.3s ease, background-color 0.3s ease;
}

.dotstyle-stroke li a:hover,
.dotstyle-stroke li a:focus {
  background-color: white;
}

.dotstyle-stroke li.current a {
  background-color: transparent;
  box-shadow: 0 0 0 2px white;
}

/* Fill in */

.dotstyle-fillin li a {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px white;
  transition: box-shadow 0.3s ease;
}

.dotstyle-fillin li a:hover,
.dotstyle-fillin li a:focus {
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.6);
}

.dotstyle-fillin li.current a {
  box-shadow: inset 0 0 0 8px white;
}

/* Circle grow */

.dotstyle-circlegrow li a {
  overflow: hidden;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px white;
  transition: opacity 0.3s ease;
}

.dotstyle-circlegrow li a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  border-radius: 50%;
  background-color: #fff;
  transform: scale(0);
  transform-origin: 50% 50%;
  transition: transform 0.3s ease;
}

.dotstyle-circlegrow li a:hover,
.dotstyle-circlegrow li a:focus {
  opacity: 0.7;
}

.dotstyle-circlegrow li.current a::after {
  transform: scale(1);
}

/* Dot to stroke */

.dotstyle-dotstroke li a {
  box-shadow: inset 0 0 0 8px rgba(255, 255, 255, 0.5);
  transition: box-shadow 0.3s ease;
}

.dotstyle-dotstroke li a:hover,
.dotstyle-dotstroke li a:focus {
  box-shadow: inset 0 0 0 8px white;
}

.dotstyle-dotstroke li.current a {
  box-shadow: inset 0 0 0 2px white;
}

/* SVG draw circle stroke */

.dotstyle-drawcircle li {
  width: 18px;
  height: 18px;
}

.dotstyle-drawcircle li a {
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  background-color: #c44d48;
  transition: opacity 0.3s ease;
}

.dotstyle-drawcircle li svg {
  z-index: 10;
}

.dotstyle-drawcircle li svg circle {
  opacity: 0;
  fill: none;
  stroke: #fff;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 39 39;
  stroke-dashoffset: 39;
  /* length of circle path (pi*2r) */
  transition: stroke-dashoffset 0.3s, opacity 0.3s;
}

.dotstyle-drawcircle li.current a,
.dotstyle-drawcircle li a:hover,
.dotstyle-drawcircle li a:focus {
  opacity: 0.5;
}

.dotstyle-drawcircle li.current svg circle {
  opacity: 1;
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 0.3s, opacity 0.15s;
}

/* Small dot with stroke */

.dotstyle-smalldotstroke li {
  border-radius: 50%;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0);
  transition: box-shadow 0.3s ease;
}

.dotstyle-smalldotstroke li a {
  background-color: rgba(255, 255, 255, 0.7);
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.dotstyle-smalldotstroke li a:hover,
.dotstyle-smalldotstroke li a:focus,
.dotstyle-smalldotstroke li.current a {
  background-color: white;
}

.dotstyle-smalldotstroke li.current a {
  transform: scale(0.4);
}

.dotstyle-smalldotstroke li.current {
  box-shadow: 0 0 0 2px white;
}

/* Puff */

.dotstyle-puff li a {
  border: 2px solid #fff;
  transition: border-color 0.3s ease;
}

.dotstyle-puff li a::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  background: #c44d48;
  border-radius: 50%;
  box-shadow: 0 0 1px #c44d48;
  opacity: 0;
  transform: scale(3);
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0s 0.3s;
}

.dotstyle-puff li a:hover,
.dotstyle-puff li a:focus,
.dotstyle-puff li.current a {
  border-color: #c44d48;
}

.dotstyle-puff li.current a::after {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

/* 3D Flip */

.dotstyle-flip li {
  perspective: 1000px;
}

.dotstyle-flip li a {
  background-color: transparent;
  transform-style: preserve-3d;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.dotstyle-flip li a::before,
.dotstyle-flip li a::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: white;
  content: "";
  text-indent: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.dotstyle-flip li a::after {
  background-color: #c44d48;
  transform: rotateY(180deg);
}

.dotstyle-flip li a:hover,
.dotstyle-flip li a:focus {
  opacity: 0.8;
}

.dotstyle-flip li.current a {
  transform: rotateY(180deg);
}

/* Tooltip */

.dotstyle-tooltip li {
  border: 2px solid #fff;
  border-radius: 50%;
  z-index: 1;
  transition: border-color 0.3s ease;
}

.dotstyle-tooltip li a {
  top: auto;
  bottom: 250%;
  left: 50%;
  visibility: hidden;
  padding: 0 10px;
  width: auto;
  height: auto;
  border-radius: 0;
  background-color: #c44d48;
  color: #fff;
  text-indent: 0;
  line-height: 2;
  opacity: 0;
  transform: translateX(-50%) translateY(-50%);
  transition: transform 0.3s ease, opacity 0.3s ease, visibility 0s 0.3s ease;
}

.dotstyle-tooltip li a::after {
  /* http: //cssarrowplease.com/ */
  position: absolute;
  left: 50%;
  margin-left: -10px;
  width: 0;
  height: 0;
  border: solid transparent;
  border-width: 10px;
  border-color: transparent;
  border-top-color: #c44d48;
  content: "";
  pointer-events: none;
  top: 99%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: opacity 0.3s ease;
}

.no-touch .dotstyle-tooltip li:hover a,
.dotstyle-tooltip li.current a {
  z-index: 100;
  visibility: visible;
  opacity: 1;
  transform: translateX(-50%) translateY(0%);
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.dotstyle-tooltip li.current {
  border-color: #c44d48;
}

.dotstyle-tooltip li:hover {
  z-index: 100;
}

.no-touch .dotstyle-tooltip ul:hover li.current a {
  opacity: 0.2;
}

.dotstyle-tooltip ul li.current:hover a {
  opacity: 1;
}

/* Dot move */

.dotstyle-dotmove li:last-child {
  position: absolute;
  left: 0;
  margin: 0;
  width: 48px;
  transition: transform 0.3s ease;
}

.dotstyle-dotmove li:last-child::after {
  content: "";
  position: absolute;
  left: 50%;
  top: -1px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #c44d48;
  transform: translateX(-50%);
}

.dotstyle-dotmove li.current:first-child ~ li:last-child {
  transform: translateX(0%);
}

.dotstyle-dotmove li.current:nth-child(2) ~ li:last-child {
  transform: translateX(100%);
}

.dotstyle-dotmove li.current:nth-child(3) ~ li:last-child {
  transform: translateX(200%);
}

.dotstyle-dotmove li.current:nth-child(4) ~ li:last-child {
  transform: translateX(300%);
}

.dotstyle-dotmove li.current:nth-child(5) ~ li:last-child {
  transform: translateX(400%);
}

.dotstyle-dotmove li.current:nth-child(6) ~ li:last-child {
  transform: translateX(500%);
}

.dotstyle-dotmove li a {
  transition: background-color 0.3s ease;
}

.dotstyle-dotmove li a:hover,
.dotstyle-dotmove li a:focus {
  background-color: #fff;
}

/* Fly in */

.dotstyle-hop li a {
  border: 2px solid #c44d48;
  background: transparent;
}

.dotstyle-hop li a::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  border-radius: 50%;
  visibility: hidden;
  background: #fff;
  opacity: 0;
  transform: rotate(-100deg);
  transform-origin: -200% 50%;
  transition: transform 0.3s ease 0.3s, opacity 0.3s ease,
    visibility 0s 0.3s ease;
}

.dotstyle-hop li a:focus {
  background: rgba(255, 255, 255, 0.2);
}

.dotstyle-hop li.current-from-right a::after {
  transition: none;
  transform: rotate(100deg);
  transform-origin: 300% 50%;
}

.dotstyle-hop li.current a::after {
  visibility: visible;
  opacity: 1;
  transform: rotate(0deg);
  transition: transform 0.3s ease, opacity 0.3s ease;
}

/* Fall */

.dotstyle-fall li::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  left: 0;
  background-color: #c44d48;
  border-radius: 50%;
  transform: translateY(-200%);
  transition: transform 0.3s ease, opacity 0.3s ease, visibility 0s 0.3s;
}

.dotstyle-fall li a {
  transition: transform 0.3s ease, opacity 0.3s ease, background-color 0.3s ease;
}

.dotstyle-fall li:hover a,
.dotstyle-fall li a:focus,
.dotstyle-fall li.current a {
  background-color: #fff;
}

.dotstyle-fall li.current::after {
  opacity: 1;
  visibility: visible;
  transition: transform 0.3s ease, opacity 0.3s ease;
  transform: translateY(0%);
}

.dotstyle-fall li.current a {
  opacity: 0;
  transform: translateY(200%);
}

.home-nav {
  position: fixed;
  top: 25vh;
  right: 30px;
}

.video-container {
  height: 330px;
}

.contact-icon-custom .fa {
  font-size: 4rem;
  line-height: 0;
}

.contact-icon-custom span {
  font-size: 1.5rem;
  font-weight: 100;
}

.custom-contact-box {
  border: 3px solid #353535;
}

.custom-design-desc {
  background-image: url("https://web-images-divina.s3.ap-south-1.amazonaws.com/website-images-content/sketches/customization2.png");
  background-repeat: no-repeat;
  height: 720px;
}

.shape-img {
  -webkit-filter: invert(1) sepia(1) saturate(0) hue-rotate(0deg);
  filter: invert(1) sepia(1) saturate(0) hue-rotate(0deg);
}

.selected-shapes {
  background-color: #585858;
}

.detailed-table td {
  border-top: 0 !important;
  padding: 5px 0px;
}

.bg-black {
  background-color: #000 !important;
}

.react-loading-skeleton {
  background-color: #eeeeee1c !important;
  background-image: linear-gradient(
    90deg,
    #eeeeee00,
    #f5f5f514,
    #eeeeee03
  ) !important;
}

.dotstyle-scaleup li .active {
  background-color: white;
  transform: scale(1.5);
}

.product-action-buttons a:hover {
  text-decoration: none;
}

.show-more {
  margin-left: -80px !important;
  margin-top: 38px !important;
}

.filterBtn {
  border: 1px solid;
  margin-right: 5px;
  padding: 5px 5px !important;
  background: transparent;
  color: white;
  margin-bottom: 10px;
  cursor: pointer;
}

.filterBtnSelected {
  background: #343a40;
}

.filterInput:focus {
  background: transparent !important;
  border-color: #ffffff;
  outline: 0;
  box-shadow: 0 0 9px 0.4rem rgb(255 255 255 / 13%);
}

.table tbody tr.custom-tr:nth-child(even) {
  background: #1d1d1d;
}

.advanceFilterBox {
  background: #1d1d1d;
  margin-top: 40px;
  padding: 20px;
}

.filterSelect {
  background-color: #000000 !important;
  color: white;
}

.filterSelect option {
  margin: 40px;
  background: #000000;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

.hideFilter {
  -webkit-animation: hide 0.5s all ease-in-out;
  animation: hide 0.5s all ease-in-out;
  height: 0;
  overflow: hidden;
}

@-webkit-keyframes hide {
  0% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
}

@keyframes hide {
  0% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
}

.showFilter {
  -webkit-animation: show 0.5s all ease-in-out;
  animation: show 0.5s all ease-in-out;
  height: 0;
  overflow: hidden;
}

@-webkit-keyframes show {
  0% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
}

@keyframes show {
  0% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
}

.hideResult:hover,
.hideResult.active {
  color: red;
}

.addToCompare:hover,
.addToCompare.active {
  color: #00adff;
}

.addToShortList:hover,
.addToShortList.active {
  color: #ffeb00cc;
}

.threedvideo {
  color: #4a4949;
}

.threedvideo.active {
  color: #7ee809;
}

.custom-tr {
  cursor: pointer;
}

.diamond-table-head {
  background: #f0f0f0;
  color: #353535;
}

.diamond-table td,
.diamond-table th {
  border: none;
}

.userBox {
  position: absolute;
  right: 20px;
  z-index: 99999;
}

.categoryFlterList {
  cursor: pointer;
  /* border: 1px solid; */
  padding: 0px 50px;
}

.categoryFlterList.activeCategory,
.categoryFlterList:hover {
  background: #3a3838b0;
}

.Fancy.Color__control.css-yk16xz-control,
.Fancy.Color__control--is-focused,
.Fancy.Color__control--is-menu-open {
  background-color: hsl(0deg 0% 0%);
}

.Fancy.Color__menu {
  background-color: hsl(0deg 0% 0%);
}

.Fancy.Color__menu div > div:hover,
.Fancy.Color__menu div > div.active {
  background-color: #343a40;
}

.Fancy.Color__control .css-1rhbuit-multiValue {
  background-color: hsl(210deg 10% 23%);
}

.Fancy.Color__control .css-12jo7m5 {
  color: white;
}

.product-box {
  padding: 15px;
  /* border: 1px solid; */
  box-shadow: 1px 1px 10px 1px #404040;
  text-align: center;
  position: relative;
  transition: 0.5s all ease-in-out;
  cursor: pointer;
  margin-bottom: 40px;
  border-radius: 10px;
}

.product-box-effect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0%;
  background: rgb(0 0 0 / 0.75);
  font-size: 28px;
  opacity: 0;
  transition: 0.5s all ease-in-out;
}

.product-box:hover .product-box-effect {
  opacity: 1;
  transition: 0.5s all ease-in-out;
  height: 100%;
}

.product-box-effect p {
  font-weight: 300;
  font-size: 20px;
}

.product-image-box {
  width: 100%;
  height: 220px;
}

.product-image-box img {
  height: 100%;
  object-fit: contain;
}

.userDropdown > .dropdown-menu {
  top: 200%;
  transition: 0.3s all ease-in-out;
}

.userDropdown:hover > .dropdown-menu {
  display: block;
  top: 100%;
}

.userDropdown > .dropdown-toggle:active {
  /*Without this, clicking will make it sticky*/
  pointer-events: none;
}

.thum-img-container {
  /* border: 1px solid #3e3d3d; */
  box-shadow: 1px 1px 8px 1px #353535;
  padding: 5px;
  border-radius: 10px;
}

.thum-img-container img {
  border-radius: 10px;
}

.main-img-container {
  padding: 15px;
  /* border: 2px solid; */
  text-align: center;
  box-shadow: 1px 1px 7px 1px #3c3c3c;
  border-radius: 10px;
}

.slider-left-layer {
  position: absolute;
  left: 0;
  width: 140px;
  bottom: 0;
  background: #000000c9;
  height: 100%;
  z-index: 1;
}

.slider-right-layer {
  position: absolute;
  right: 0;
  width: 140px;
  bottom: 0;
  background: #000000c9;
  height: 100%;
  z-index: 1;
}

.btn-primary {
  background: black;
  border: 1px solid #484848;
  padding: 7px 85px;
}

.btn-primary:hover {
  background-color: #212121a6;
  border-color: #5d5d5d;
}

.btn-primary:focus,
.btn-primary.active,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: #212121a6;
  border-color: #5d5d5d;
  outline: none !important;
  box-shadow: 0 0 0 0.2rem rgb(117 117 117 / 50%) !important;
}

.form-control {
  color: #ffffff;
  background-color: #000;
  background-clip: padding-box;
  border: 1px solid #65656521;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
}

.form-control:focus {
  color: #ffffff;
  background-color: #191919;
  border-color: #717171;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(152 152 152 / 25%);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  color: #ffffff !important;
  background-color: #191919 !important;
  border-color: #717171;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(152 152 152 / 25%);
}

.appointmentBox {
  background: #0e0e0e;
  padding: 15px;
  box-shadow: 1px 1px 3px 1px #232323;
}

select.form-control:not([size]):not([multiple]) {
  border: 1px solid #a0a0a0;
}

.resetFilterBtn:hover {
  text-decoration: underline;
}

.footer {
  background: linear-gradient(#000000, rgb(143 140 140 / 31%));
}

.toaster.success {
  color: #30a001;
}

.toaster.danger {
  color: #e01a1a;
}

.react-responsive-modal-overlay {
  background: rgb(255 255 255 / 75%) !important;
  align-items: center !important;
}

.react-responsive-modal-modal {
  background: #000000 !important;
  width: 100%;
}

.react-responsive-modal-modal .form-control {
  background-color: #3a3a3a;
}

.react-responsive-modal-closeButton svg {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.magnifier {
  width: 390px !important;
}

.btn-white:hover {
  background: transparent;
  color: white;
  border-color: white;
}

.thum-img-container.active {
  border: 2px solid;
  padding: 5px;
}

.globleSearchBox {
  position: relative;
}

.globleSearchBox i {
  position: absolute;
  top: 10px;
  left: 10px;
}

.globleSearchBox input,
.globleSearchBox select {
  background: #1f1f1f;
  color: white;
  border: none;
}

.globleSearchBox input:focus,
.globleSearchBox select:focus {
  background-color: #000000;
  border-color: #000000;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(29 28 28);
}

.promotionBannerBox {
  background: #232323ba;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.promotionContentBox {
  /* background: white; */
  padding: 20px;
  color: #000;
  color: initial;
  text-align: center;
  padding-top: 190px;
  border-radius: 30px;
  min-width: 900px;
}

.promotionImageBox {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
}

.promotionImageBox img {
  border-radius: 30px;
}

.promotionContentBox input,
.promotionContentBox input:focus {
  background-color: #fff;
  border: 1px solid #00000021;
  color: inherit;
}

.shape-img {
  width: 75px;
  height: 75px;
}

.bread li {
  font-size: 14px;
}

.custom-cat-desc {
  position: absolute;
  left: 0;
  right: 0;
  padding: 0 200px;
}

.jewelryImageBox img {
  /* width: 500px; */
  height: 100%;
  object-fit: contain;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
}

.gallery-close-icon {
  position: absolute;
  top: 15px;
  left: 25px;
  padding: 5px 10px;
  border-radius: 50px;
  background: #2f2e2e;
  cursor: pointer;
}

.gallery-change-image-box {
  position: absolute;
  top: 15px;
  right: 25px;
  display: flex;
}

.gallery-change-image-prev,
.gallery-change-image-next {
  padding: 5px 15px;
  background: #2f2e2e;
  border-radius: 50px;
  margin: 0 5px;
  cursor: pointer;
}

.form-control:disabled,
.form-control[readonly] {
  background: #181818;
  cursor: not-allowed;
}

.searchOptionBox {
  position: absolute;
  top: 40px;
  left: 0;
  padding: 0;
  background: #1f1f1f;
  z-index: 1;
  border-radius: 2px;
  left: 0;
  right: 0;
  box-shadow: 1px 1px 7px 1px #212121;
  color: white;
  max-height: 450px;
  overflow-y: auto;
}

.searchOptionBox ul {
  padding: 0;
  list-style-type: none;
  margin: 0;
}

.searchOptionBox ul li {
  padding: 10px 15px;
  color: white;
  width: 100%;
}

.searchOptionBox ul li:hover {
  background: #000;
}

.react-multi-carousel-list {
  justify-content: center;
}

.react-multi-carousel-list ul li a > div {
  width: 150px;
}

.estateGalleryBox {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1111;
  background: #1f1f1feb;
}

.estateGalleryBox > .close {
  position: fixed;
  z-index: 9999;
  background: white;
  font-size: 20px;
  top: 25px;
  right: 40px;
  cursor: pointer;
  padding: 12px 15px;
  cursor: pointer;
  border-radius: 50px;
}

.sidebar {
  position: fixed;
  left: 0;
  width: 285px;
  background: black;
  z-index: 999;
  bottom: 0;
  top: 0;
  height: 100vh;
  box-shadow: 1px 1px 13px 1px #404040;
  padding: 20px 10px;
  display: none;
}

.sidebar-body ul,
.sidebar-footer ul {
  padding: 0;
  list-style-type: none;
}

.sidebar-body ul li,
.sidebar-footer ul li {
  margin: 5px 0px;
  padding: 5px;
}

.item-box {
  padding: 10px;
  background: #171717c7;
  border-radius: 5px;
}

.item-box img {
  border-radius: 5px;
}

.Vision360 {
  background: #000000 !important;
}

.blog-image {
  height: 250px;
}

.blog-image img {
  object-fit: cover;
}

.home-page-video-banner {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-page-video-banner img {
  width: 100%;
}

/* Large devices (desktops, less than 1200px) */

@media (max-width: 1199.98px) {
}

/* Medium devices (tablets, less than 992px) */

@media (max-width: 991.98px) {
  .divina-video #background-video {
    display: none;
  }
  .bg-fixed {
    background-attachment: scroll;
  }
  .sidebar {
    display: block;
  }
  .sidebar-body {
    max-height: 70%;
    overflow-x: auto;
  }
  .product-image-box {
    height: 120px;
  }
  .cat-jumbo {
    height: 200px;
  }
  .custom-cat-desc {
    margin-top: 15px;
    position: relative;
    padding: 0;
  }
}

/* Small devices (landscape phones, less than 768px) */

@media (max-width: 767.98px) {
  .promotionImageBox img {
    width: 55%;
  }
  .category-heading-name {
    font-size: 30px;
  }
  .bg-fixed {
    background-repeat: no-repeat;
  }
  .jwelery-cats {
    /* display: flex;
        justify-content: space-around; */
  }
  .jwelery-cats li {
    margin: 0;
    display: block;
    padding: 20px;
  }
  .jwellery-cat-banner {
    height: 400px;
    padding: 0;
  }
  .jewelryImageBox {
    height: 100%;
  }
  .jewelryImageBox img {
    width: 100%;
  }
  .cat-jumbo {
    height: 150px;
  }
  .jumbo-cat-name {
    font-size: 2.3rem;
  }
  .desc-text {
    font-size: 14px;
  }
  .product-image-box {
    height: 100px;
  }
  .display-4 {
    font-size: 1.7rem;
  }
  .contact-icon-custom .fa {
    font-size: 2rem;
  }
  .contact-icon-custom span {
    font-size: 1rem;
  }
  .product-box {
    width: 100%;
  }
  .contact-heading {
    font-size: 45px;
  }
  .text-big-35 {
    font-size: 25px;
  }
  .magnifier {
    width: 250px !important;
  }
  .login-box {
    padding: 15px !important;
  }
  .wc-divina-text {
    font-size: 16px;
  }
  .diamond-table p {
    font-size: 11px;
  }
  .ft-lato-bold {
    font-size: 10px;
  }
  .item-box .fs-28 {
    font-size: 20px;
  }
  .shape-img {
    width: 50px;
    height: 50px;
  }
  .not-show-more {
    height: 150px;
  }
  .advanceFilterBox {
    margin-top: 10px;
    padding: 10px;
  }
}

/* Extra small devices (portrait phones, less than 576px) */

@media (max-width: 575.98px) {
  .promotionImageBox img {
    width: 35%;
  }
}

@page {
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  /* Letter */
  size: us-letter;
  margin: 1in;
}

#diamond-pdf-table {
  color: #000;
  font-size: 5px;
}

#diamond-pdf-table table tbody tr:nth-child(even),
#diamond-pdf-table table thead tr {
  background: #e2e2e2;
}


/*  */
.custom-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-auto-flow: row;
}
